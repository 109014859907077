.grid {
  border-radius: 10px;
  margin: 25px 100px 5px 100px;
  position: relative;
  justify-content: center;
}
.gridArea {
  justify-content: center;
  display: grid;
  width: auto;
  height: 400px;
  grid-template-rows: 260px;
  grid-template-columns: 2fr 3fr;
  grid-template-areas: "before after";
}

.textBefore {
  color: aliceblue;
  position: absolute;
  top: 260px;
  margin-left: 15px;
  font-size: 20px;
}
.textAfter {
  position: absolute;
  top: 350px;
  margin-left: 15px;
  font-size: 20px;
  color: aliceblue;
}

.before {
  height: 300px;
  background-size: cover;
  margin: 5px;
  border: 10px solid var(--clr2);
}

.after {
  background-size: cover;
  height: 390px;
  margin: 5px;

  border: 10px solid var(--clr4);

}

@media only screen and (max-width: 950px){
  .grid {
    margin: 25px;
}
.before{
  border: 5px solid var(--clr2);
}

.after{
  border: 5px solid var(--clr4);
}
}

@media only screen and (max-width: 510px){
  .grid{
    margin: 5px;
  }
  .gridArea{
    height: 300px;
  }
.before{
  height: 200px
}
.after{height: 300px;}
.textBefore{
  top: 155px;
}
.textAfter{
  top:255px;
}
}



