:root {
	--clr1: #5ce5d3;
	--clr2: #15de07;
	--clr3: #9dcf60;
	--clr4: #f3d676;
	--clr5: #f2981a;
}

.app {
  text-align: center;
  background-color: var(--clr1);
  min-height: 100%;
  padding-bottom: 60px;
  position: relative;
}
html, body {
	min-height: 100%;
	display: flexbox;
	flex-direction: column;
}

.body-section{
	justify-content: center;
}

.wrapper{
	margin: 10px;
	justify-content: center !important;
	display: inline;
}
