.navEx{
background-color: var(--clr4);
margin: 0;
height: 40px;
color: aliceblue;
padding-top: 10px;
}

h4 {
font-size: 20px;
margin: 5px;

}
.number{
    color: var(--clr2);
    text-decoration:  none;
    font-size: 25px;
    margin-top: 10px;
}

.number:hover{
    color: var(--clr1);
}


