.nav-item {
  list-style: none;
  padding-left: 50px;
  padding-right: 50px;
  color: var(--clr3) !important;

 
}

.nav-link {
  display: block;
  text-decoration: none;
  color: white !important;
  padding-top: 10px;

  
  font-size: 30px;
}

.nav-link:hover {
 
  border-bottom: 5px dotted;
  border-color: var(--clr5);
  
}
.navbar-nav.justify-content-center {
  flex-direction: row !important;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 60px;
}

.container-fluid.justify-content-center {
  color: var(--clr4); 

  text-shadow: 1px 1px var(--clr1);
  background-color: var(--clr3) !important;
}
.nav-link.active {
 
  text-shadow: 1px 1px var(--clr5);
 
}

@media only screen and (max-width: 510px){
.nav-item{
  padding-left: 25px;
  padding-right: 25px;
}
.nav-link {
  font-size: 20px !important;
  margin-top:  10px;
}  
}

