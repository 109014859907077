.belowHero {
  height: 110px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: var(--clr1);
  padding-top: 13px;
  border: 4px var(--clr4)
}

.belowHero h2 {
  font-size: 30px;
  color: white;
 
  margin: 0;
}

.loactionUlDiv{
  text-shadow: 1px 1px var(--clr5);
}
.belowBelow{
  background-color: var(--clr3);
  position: relative;
  height: 90px;
  color: aliceblue;

}
.belowLocation {
  margin: 0;
  padding: 10px;
  text-shadow: 1px 1px var(--clr5);
  font-size: 25px;
}

.location {
  list-style: none;
  position: relative;
  display: inline;
  padding: 30px;
  text-shadow: 1px 1px var(--clr2);
 font-size: 25px;
}
.loactionUl{
  padding-left: 0;
  display: inline;
}


@media only screen and (max-width: 480px){
  .belowHero h2 {
    font-size: 25px;
   
  }
}

@media only screen and (min-width: 961px){
  .belowHero {
    height: 80px;
   
  }
}

@media only screen and (max-width: 886px){
  .location{
   display: block;
   padding: 5px;
   font-size: 20px;
  }
 
}

@media only screen and (max-width: 550px){
  .belowHero{
   height: 130px;
  }
  
 }

@media only screen and (max-width: 383px){
 .belowBelow {
  height: 120px;
 }
 
}