

.hero {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
}

.hero h1 {
  font-size: 80px;
  padding-top: 120px;
  margin-top: 0;
  color: var(--clr2);
  text-shadow: 2px 2px var(--clr5);
}

.hero h2 {
  font-size: 60px;
  color: var(--clr5);
  text-shadow: 2px 2px var(--clr1);
}

@media only screen and (max-width: 480px){
  .hero h1 {
    font-size: 50px;
  }
  .hero {
    height: 360px;
  }
}

@media only screen and (max-width: 550px){
  .hero h1 {
   font-size: 60px;
  }
  
 }

 @media only screen and (max-width: 410px){
  .hero h1 {
   font-size: 40px;
  }
  
 }