.footer {
  color: aliceblue;
   margin-top: auto;
   height: 60px;
   width: 100%;
   position: absolute;
   left:0;
   right:0;
   bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
  border-top: 5px;
  border-color: var(--clr3);
  background-color: var(--clr3);
}

.footer-item {
  list-style: none;
  padding-left: 50px;
  padding-right: 50px;
  color: var(--clr3) !important;

 
}

.footer-partent{
  position: relative;
  bottom: 0;
  width: 100%;
}

.footerLinks {
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  list-style: none;
  width: 100%;
  padding: 0;
  color: var(--clr1);
  background-color: var(--clr3) !important;
  display: inline-flex;
}

.footerLinks:hover {
  color: var(--clr5);
}
